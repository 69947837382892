import { format } from "date-fns";

export const getItemByKey = (items = [], key, value) =>
  items.find(item => item[key] === value);

export const getItemById = (items = [], value) =>
  getItemByKey(items, "id", value);

export const getItemKey = (items = [], key, value) => {
  const item = getItemById(items, value);
  return item ? item[key] : undefined;
};

export const getItemName = (items = [], value) =>
  getItemKey(items, "name", value);

export const getFirstLastName = contact => {
  const { firstName } = contact.primaryContact || contact;
  const { lastName } = contact.primaryContact || contact;
  return { firstName: firstName || "", lastName: lastName || "" };
};

export const getContactName = contact => {
  const { firstName, lastName } = getFirstLastName(contact);
  return `${firstName} ${lastName}`.trim();
};

export const getEnv = key => process.env[`REACT_APP_${key}`];

export const getSecret = () =>
  btoa(`${getEnv("CLIENT_ID")}:${getEnv("CLIENT_SECRET")}`);

export const getLSItem = key => window.localStorage.getItem(key);
export const setLSItem = (key, value) =>
  window.localStorage.setItem(key, value);
export const removeLSItem = key => window.localStorage.removeItem(key);

export const getPremium = (policy = {}) => {
  const { insuredFullToBePaidAmt } = policy;
  return insuredFullToBePaidAmt ? `$${insuredFullToBePaidAmt}` : "-";
};

export const getFormattedDate = date =>
  date && format(new Date(date), "MM/dd/yyyy");

export const getQuestionIdAnswerObj = (questionsToBeFilled = []) =>
  questionsToBeFilled
    .map(question => {
      const { id } = question.question;
      let value = null;
      if (question.selectedChoices && question.selectedChoices.length) {
        value = question.selectedChoices
          .map(({ responseChoice }) => responseChoice)
          .join(",");
      } else {
        // @TODO: FIND A BETTER ALTERNATIVE FOR UNDEFINED
        [value] = question.responseValue || [undefined];
      }
      return { [id]: value };
    })
    .reduce((finalObj, currentObj) => {
      const [key] = Object.keys(currentObj);
      return { ...finalObj, [key]: currentObj[key] };
    }, {});

export const getCurrency = value =>
  "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getCommaSeparated = value =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getPhoneNumberFormatted = (value = "") => {
  if (!value) {
    return "";
  }

  const cleaned = value.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return "";
};

export const getErrorJSON = err => {
  if (err.json) {
    return err.json();
  }
  return Promise.resolve({});
};
