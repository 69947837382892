import React from "react";
import Toast from "../components/Toast";

const FormErrorContext = React.createContext();
let timeout;

const useToast = () => {
  const context = React.useContext(FormErrorContext);
  if (!context) {
    throw new Error(`useToast must be used within a ToastProvider`);
  }
  const [toast, setToast] = context;
  const openToast = ({
    duration = 3000,
    isSuccess = true,
    message = isSuccess ? "Success" : "Error"
  }) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    setToast({
      show: true,
      message,
      isSuccess
    });
    timeout = setTimeout(() => {
      setToast({ ...toast, show: false });
    }, duration);
  };
  return {
    toast,
    setToast,
    openToast
  };
};

const ToastProvider = ({ children, ...props }) => {
  const [toast, setToast] = React.useState({});
  const value = React.useMemo(() => [toast, setToast], [toast]);
  return (
    <FormErrorContext.Provider value={value}>
      {children}
      <Toast show={!!toast.show} isSuccess={toast.isSuccess}>
        {toast.message}
      </Toast>
    </FormErrorContext.Provider>
  );
};

export { ToastProvider, useToast };
