import { getLSItem, removeLSItem, setLSItem } from "./index";
import { refreshToken } from "./request";

const TOKEN_KEY = "token";
const EXPIRE_BUFFER = 20;

export const autoRefreshTokenCreator = (() => {
  let timeout;
  return token => {
    timeout = autoRefreshToken(token, timeout);
  };
})();

export const autoRefreshToken = (token, timeout) => {
  const { absoluteExpiryTime } = token;
  const currentMs = Date.now();
  const expireTime = absoluteExpiryTime - currentMs;
  if (timeout) {
    clearTimeout(timeout);
  }
  timeout = setTimeout(refreshToken, expireTime);
  return timeout;
};

export const getTokenWithAbsoluteExpiryTime = token => {
  const { expires_in: expiresIn } = token;
  const currentMs = Date.now();
  const absoluteExpiryTime =
    currentMs + expiresIn * 1000 - EXPIRE_BUFFER * 1000;
  return { ...token, absoluteExpiryTime };
};

export const setTokenWithAbsoluteExpiryTimeAndRefresh = token => {
  const value = getTokenWithAbsoluteExpiryTime(token);
  setToken(value);
  autoRefreshTokenCreator(value);
};
export const getToken = () => JSON.parse(getLSItem(TOKEN_KEY) || "{}");

export const setToken = value =>
  setLSItem(TOKEN_KEY, JSON.stringify(value || {}));
export const removeToken = () => removeLSItem(TOKEN_KEY);
