import React from "react";
import {
  getToken,
  removeToken,
  getTokenWithAbsoluteExpiryTime,
  autoRefreshTokenCreator,
  setTokenWithAbsoluteExpiryTimeAndRefresh
} from "../utils/token";
import { getEnv, getSecret } from "../utils";

const AuthContext = React.createContext();

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

const AuthProvider = props => {
  const login = ({ email, password }) => {
    removeToken();
    const secret = getSecret();
    const BASE_URL = getEnv("BASE_URL");

    return fetch(`${BASE_URL}oauth/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${secret}`
      },
      body: `grant_type=password&username=${email}&password=${password}`
    })
      .then(response => {
        const json = response.json();
        if (!response.ok) {
          return Promise.reject(json);
        }
        return json;
      })
      .then(token => {
        setTokenWithAbsoluteExpiryTimeAndRefresh(token);
        setIsAuthenticated(true);
        return token;
      })
      .catch(error => {
        setIsAuthenticated(false);
        return Promise.reject(error);
      });
  };

  const logout = () => {
    removeToken();
    setIsAuthenticated(false);
    window.location = "/";
  };
  const value = getToken() || {};
  const { access_token: token } = value;

  if (!!token) {
    const tokenWithAbsoluteExpiryTime = getTokenWithAbsoluteExpiryTime(value);
    autoRefreshTokenCreator(tokenWithAbsoluteExpiryTime);
  }

  const [isAuthenticated, setIsAuthenticated] = React.useState(!!token);
  const useAuthenticated = React.useMemo(
    () => [isAuthenticated, setIsAuthenticated],
    [isAuthenticated]
  );
  return (
    <AuthContext.Provider
      value={{ useAuthenticated, login, logout, getToken }}
      {...props}
    />
  );
};

export { AuthProvider, useAuth };
