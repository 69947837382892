import React from "react";
import { createStyled } from "styletron-react";
import { driver, getInitialStyle } from "styletron-standard";
import { ThemeContext } from "../contexts/ThemeContext";

const wrapper = StyledComponent => {
  return function withThemeHOC(props) {
    return (
      <ThemeContext.Consumer>
        {({ tokens, designVars }) => (
          <StyledComponent {...props} $theme={tokens} $vars={designVars} />
        )}
      </ThemeContext.Consumer>
    );
  };
};

export default createStyled({ wrapper, getInitialStyle, driver });
