import React from "react";

const TOKENS = {
  light: {
    primary: "#212121",
    secondary: "#757575",
    disabled: "#9E9E9E",
    background: "#212121"
  },
  dark: {
    primary: "#FFFFFF",
    secondary: "#B3B3B3",
    disabled: "#4D4D4D",
    background: "#FBFBFB"
  }
};

const DEFAULT_TOKENS = {
  red: "#F04D46",
  teal: "#3BC4BD",
  navyBlue: "#3B3B95",
  lightGrey: "#EEEEEE",
  white: "#FFFFFF",
  readyGreen: "#3DBE18",
  warning: "#F1AC22"
};

const DESIGN_VARS = {
  colors: {
    dashboardDark: "#3B3B95",
    primaryBrand: "#1F1F7C",
    primaryBrandLight: "#E6EBF4",
    accentBrand: "#1EBBEC",
    primaryDark: "#212121",
    secondaryDark: "#757575",
    lightGrey: "#EEEEEE",
    white: "#FFFFFF",
    background: "#F4F5F7",
    disabledGrey: "#9E9E9E",
    success: "#3DBE18",
    danger: "#E93435",
    warning: "#F1AC22"
  }
};

const getTokensByTheme = theme => ({
  ...DEFAULT_TOKENS,
  ...TOKENS[theme]
});

const ThemeContext = React.createContext();

const useTheme = () => {
  const context = React.useContext(ThemeContext);
  if (!context) {
    throw new Error(`useTheme must be used within a ThemeProvider`);
  }
  const { tokens, value, designVars } = context;
  const [theme, setTheme] = value;
  return {
    theme,
    setTheme,
    tokens,
    designVars
  };
};

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = React.useState("light");
  const tokens = getTokensByTheme(theme);
  const designVars = DESIGN_VARS;
  const value = React.useMemo(() => [theme, setTheme], [theme]);

  return (
    <ThemeContext.Provider value={{ value, tokens, designVars }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext, useTheme };
