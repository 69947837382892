import { getEnv, getSecret } from "./index";
import {
  getToken,
  setTokenWithAbsoluteExpiryTimeAndRefresh,
  removeToken
} from "./token";
import fetch from "./fetch";

const resetApp = () => {
  removeToken();
  return (window.location = "/");
};

const BASE_URL = getEnv("BASE_URL");
let RENEW_COUNT = 0;
const RENEW_COUNT_THRESHOLD = 7;

export const refreshToken = () => {
  const secret = getSecret();
  const { refresh_token: token } = getToken() || {};
  return fetch(`${BASE_URL}oauth/token`, {
    method: "POST",
    body: `grant_type=refresh_token&refresh_token=${token}`,
    headers: {
      Authorization: `Basic ${secret}`,
      "Content-Type": "application/x-www-form-urlencoded"
    }
  })
    .then(async response => {
      if (response.json) {
        const token = await response.json();
        setTokenWithAbsoluteExpiryTimeAndRefresh(token);
      }
    })
    .catch(async e => {
      if (e.json) {
        const { error } = await e.json();
        if (error === "invalid_grant") {
          if (RENEW_COUNT === RENEW_COUNT_THRESHOLD) {
            resetApp();
          }
          RENEW_COUNT += 1;
        }
      }
    });
};

// @TODO: avoid taking requests while the token is updating
const request = (url, config) => {
  const AuthorizationHeader = {};
  const { access_token: token } = getToken() || {};
  if (token) {
    AuthorizationHeader["Authorization"] = `Bearer ${token}`;
  }
  const { headers } = config;
  const finalConfig = {
    ...config,
    headers: { ...headers, ...AuthorizationHeader }
  };
  return fetch(url, finalConfig).catch(err => {
    if (err.message === "Failed to fetch") {
      resetApp();
    }
    const { json } = err;

    if (json) {
      return err
        .clone()
        .json()
        .then(response => {
          const { error } = response;
          if (error === "invalid_token") {
            return refreshToken().then(() => request(url, finalConfig));
          }
          return Promise.reject(err);
        })
        .catch(err => Promise.reject(err));
    }
    return Promise.reject(err);
  });
};

const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json"
};

export const get = (url = "", params = {}, config) => {
  const urlParams = new URLSearchParams(Object.entries(params));
  return request(`${encodeURI(BASE_URL + url)}?${urlParams}`, {
    method: "GET",
    headers: { ...DEFAULT_HEADERS },
    ...config
  });
};

export const post = (url, payload, config = {}) => {
  const { headers = {}, body, ...restConfigs } = config;
  return request(`${BASE_URL}${url}`, {
    method: "POST",
    headers: { ...DEFAULT_HEADERS, ...headers },
    body: body || JSON.stringify(payload),
    ...restConfigs
  });
};
export const postMultipart = (url, payload, config = {}) => {
  const formData = new FormData();
  formData.set("file", payload);
  const { headers = {}, ...restConfigs } = config;
  delete restConfigs.body;
  delete headers["Content-Type"];
  return request(`${BASE_URL}${url}`, {
    method: "POST",
    headers: { ...headers },
    body: formData,
    ...restConfigs
  });
};

export const requestDelete = (url, payload, config = {}) =>
  post(url, payload, { method: "DELETE", ...config });

export const getJSON = (...args) =>
  get(...args).then(response => response.json());
export const postText = (...args) =>
  post(...args).then(response => response.text());
export const postJSON = (...args) =>
  post(...args).then(response => response.json());
