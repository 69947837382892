import React from "react";
import ReactDOM from "react-dom";
import { Provider as StyletronProvider } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { ThemeProvider } from "./contexts/ThemeContext";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { ToastProvider } from "./contexts/ToastContext";

import "./index.css";

const Login = React.lazy(() => import("./oldScreens/Login"));
const Authenticated = React.lazy(() => import("./components/Authenticated"));

const engine = new Styletron();

const CurrentPage = () => {
  const { useAuthenticated } = useAuth();
  const [isAuthenticated] = useAuthenticated;
  if (!isAuthenticated) {
    return <Login />;
  }
  return <Authenticated />;
};

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <StyletronProvider value={engine}>
        <ThemeProvider>
          <HelmetProvider>
            <ToastProvider>
              <Helmet>
                <title> SAGE </title>
              </Helmet>
              <React.Suspense fallback={<span>&nbsp;</span>}>
                <CurrentPage />
              </React.Suspense>
            </ToastProvider>
          </HelmetProvider>
        </ThemeProvider>
      </StyletronProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
