import React from "react";
import Alert from "@reach/alert";
import { Transition } from "react-spring/renderprops";
import styled from "../utils/styled";

const Toast = styled(Alert, ({ $isSuccess = true, $theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "4px",
  boxShadow: "14px 0 14px -10px rgba(0,1,1,0.1)",
  borderLeft: `5px solid ${$isSuccess ? $theme.readyGreen : $theme.red}`,
  padding: "1.4rem",
  position: "fixed",
  bottom: "-40px",
  left: "100px",
  width: "30rem",
  zIndex: 20
}));

export default ({ show = true, children, isSuccess, ...props }) => (
  <Transition
    items={show}
    from={{ transform: "translate3d(0,-40px,0)" }}
    enter={{ transform: "translate3d(0,-80px,0)" }}
    leave={{ transform: "translate3d(0,80px,0)" }}
  >
    {show => props =>
      show && (
        <Toast style={props} $isSuccess={isSuccess}>
          {children}
        </Toast>
      )}
  </Transition>
);
